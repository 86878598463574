
<template>
  <div>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card :loading="loading" :disabled="loading" loader-height="2">
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <v-btn rounded color="primary" elevation="0" small class="mr-2" @click="addFileNames()">
              <v-icon class="mr-1">mdi-plus</v-icon> Add
            </v-btn>
            <lb-string label="Search" outlined hidedetails v-model="seachList"></lb-string>
            <v-spacer></v-spacer>
            <v-chip label small class="border-left-error" outlined>Disabled</v-chip>
            <v-btn icon small class="ml-2" @click="getData()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text>
          <lb-datatablenew :headers="headers" :items="searchResult" :enableslot="['action','bid']" :loading="loading">
			<template v-slot:bid="{ item }">
				{{getBotName(item.bid)}}
			</template>
            <template v-slot:action="{ item }">
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" color="" @click="editfileMaster(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" color="" @click="ConfigfileMaster(item)">
                    <v-icon>mdi-file-link</v-icon>
                  </v-btn>
                </template>
                <span>File Config</span>
              </v-tooltip>
            </template>
          </lb-datatablenew>
        </v-card-text>
      </v-card>
    </div>
  </div>
  <lb-dialog v-model="dialogfile" width="600" :heading="filedeatils._id ? 'Update File' : 'Create File'" :loading="filemasterloading">
    <template v-slot:body>
      <div>
        <div class="d-flex align-center justify-center">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <lb-string label="File Name*" v-model.trim="filedeatils.name" :error="filedeatilserror.name" /> 
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <lb-string label="File Description" v-model="filedeatils.description" :error="filedeatilserror.description" />
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <lb-string label="Alias" v-model="filedeatils.alias" :error="filedeatilserror.alias"/>
            </v-col>
			<v-col cols="6" class="my-0 py-0">
               <lb-dropdown class="flux-grow-1 mr-1" label="Select Bot*" :items="botlist" v-model.trim="filedeatils.bid" itemtext="name" itemvalue="_id" :error="filedeatilserror.bid" :key="new Date().getTime()"/>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <span class="caption">*Required</span>
      <v-spacer></v-spacer>
      <v-btn small color="primary" v-if="filedeatils._id" @click="updatefileMaster(filedeatils._id)">
        <span >Update</span>
      </v-btn>
      <v-btn small color="primary" v-else @click="addfileMaster()">
        <span>Add</span>
      </v-btn>
    </template>
  </lb-dialog>
</div>
</template>
<script>
export default {
	data() {
		return {
			loading: false,
			filemasterloading: false,
			dialogfile: false,
			filedeatils: {},
			filedeatilserror: {},
			formatedData: [],
			headers: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "name",
					text: "Name",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "description",
					text: "Description",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "alias",
					text: "Alias",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "bid",
					text: "Bot",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "action",
					text: "Action",
					sortable: false,
					filterable: false,
				},
			],
			getfiledata: [],
			botlist:[],
			seachList:'',
			copygetfiledata:[],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		formateData() {
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.getfiledata.forEach((i, k) => {
				ObjectTemp = {
					_id: i._id,
					indexno: k + 1,
					name: i.name,
					description: i.description,
					alias: i.alias,
					bid:i.bid,
				}
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedData = ArrayTemp;
		},
		getBotName(id) {
			let bot = this.botlist.find((x) => x._id === id);
			return (bot || {}).name;
		},
		async getData() {
		this.loading = true;
		try {
			const fileMasterResponse = await this.axios.post("/v2/conserve/filemaster/get");
			if (fileMasterResponse.data.status === "success") {
			this.getfiledata = fileMasterResponse.data.data;
			console.log(this.getfiledata, "getfiledata1");
			
			// const fileResponse = await this.axios.post("/v2/conserve/file/get");
			// if (fileResponse.data.status === "success" && fileResponse.data.data.length > 0) {
			// 	this.getfiledata.push(...fileResponse.data.data);
			// }
			// console.log(this.getfiledata, "getfiledata2");
			this.formateData();
			}
		} catch (err) {
			console.log(err);
		} finally {
			this.getBot();
			this.loading = false;
		}
		},
		getBot(){
			this.loading = true;
			return this.axios.post("v2/conserve/bot/get").then(dt => {
				if (dt.data.status === "success" && dt.data.data.length > 0) {
					this.botlist = dt.data.data;
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			}).finally(() => {
				this.loading = false;
			})
		},
		addFileNames() {
			this.dialogfile = true;
			(this.filedeatilserror = {}), 
			(this.filedeatils = {});
		},
		addfileMaster() {
			console.log(this.filedeatils);
			this.filemasterloading = true;
			this.axios
				.post("/v2/conserve/filemaster/add", {
					data: this.filedeatils
				})
				.then((dt) => {
					console.log(dt);
					if (dt.data.status === "success") {
						this.$store.commit("sbSuccess", "File added successfully");
						this.getData();
						this.dialogfile = false;
					} else {
						if (dt.data.data.length > 0 && dt.data.data[0].index0)
							this.filedeatilserror = dt.data.data[0].index0;
						else throw new Error(dt.data.message);
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.filemasterloading = false;
				});
		},
		editfileMaster(item) {
			console.log(item);
			(this.filedeatilserror = {}), 
			this.dialogfile = true;
			this.filedeatils = {
				...item
			};
		},
		updatefileMaster(id) {
			this.filemasterloading = true;
			let data = {
				name: this.filedeatils.name,
				description: this.filedeatils.description,
				alias: this.filedeatils.alias,
				bid:this.filedeatils.bid,
			}
			this.axios
				.post("/v2/conserve/filemaster/edit/" + id, {
					data: data
				})
				.then((dt) => {
					if (dt.data.status === "success") {
						this.$store.commit("sbSuccess", "File updated successfully");
						this.getData();
						this.dialogfile = false;
					} else {
						if (dt.data.data.length > 0 && dt.data.data[0].index0)
							this.filedeatilserror = dt.data.data[0].index0;
						else throw new Error(dt.data.message);
					}
				})
				.catch((err) => {
					this.$store.commit("sbError", err.message || err || "Unknown error!");
					console.log(err);
				})
				.finally(() => {
					this.filemasterloading = false;
				});
		},
		ConfigfileMaster(item) {
			this.$nova.gotoLink({
				path: 'filemaster/config/' + item._id
			})
		}
	},
	computed: {
    searchResult() {
      let tempList = this.getfiledata;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.name.toUpperCase().includes(this.seachList.toUpperCase());
        });
      }

      return tempList;
    },
  },
};
</script>